import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import config from "../../../config";
import CodeLangSingleForm from "../../../components/organisms/CodeLangSingleForm/CodeLangSingleForm";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import EmailPreview from "../../../components/organisms/EmailPreview/EmailPreview";
import TextInput from "../../../components/atoms/TextInput/TextInput";
import HelpPopUp from "../../../components/organisms/HelpPopUp/HelpPopUp";
import { useNotification } from "../../../NotificationContext";
import { useAuthFetch } from "../../../hooks/useAuthFetch";
import Loader from "../../../components/atoms/Loader/Loader";
import Button from "../../../components/atoms/Button/Button";

const EditCodeLang = () => {
    const { campaignId, codeLangId } = useParams();
    const [placeholdersData, setPlaceholdersData] = useState({});
    const [emailObject, setEmailObject] = useState('');
    const [emailContent, setEmailContent] = useState('');
    const { triggerNotification } = useNotification();
    const [isLoading, setIsLoading] = useState(false);

    const helpInstructions = [
        "[[ login ]] : Affiche le login de l'éligible, et ne fonctionne que pour un envoi réel",
        "[[ password ]]: Affiche le mot de passe de l'éligible, et ne fonctionne que pour un envoi réel",
        "[[ first_name ]]: Affiche le prénom de l'éligible, et ne fonctionne que pour un envoi réel",
        "[[ last_name ]]: Affiche le nom de l'éligible, et ne fonctionne que pour un envoi réel",
        "[[ reference_price_global_iso ]]: Prix de référence en euro ISO",
        "[[ reference_price_global_sigle ]]: Prix de référence en euro Sigle (€)",
        "[[ subscription_price_global_iso ]]: Prix de souscription en euro ISO",
        "[[ subscription_price_global_sigle ]]: Prix de souscription en euro Sigle (€)",
        "[[ reference_price_local_iso ]]: Prix de référence en monnaie locale (si renseigné) en ISO",
        "[[ reference_price_local_sigle ]]: Prix de référence en monnaie locale (si renseigné) avec sigle (Si existant)",
        "[[ subscription_price_local_iso ]]: Prix de souscription en monnaie locale (si renseigné), en ISO",
        "[[ subscription_price_local_sigle ]]: Prix de souscription en monnaie locale (si renseigné) avec sigle (Si existant)",
        "Pour le template {{ placeholder }} ou {{ placeholder#1 }} {{ placeholder#2 }} pour bilangue",
        "RESPECTER LES ESPACES {{ placeholder }} et pas {{placeholder}}",
        "Pour les bannières dynamiques: **banner_1_nom.jpg** 1 représentant le code lang.",
    ];
    const authFetch = useAuthFetch(); // Nouvelle constante

    const fetchData = useCallback(async () => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/codelang/fetch/${codeLangId}`); // Remplacement de fetch par authFetch
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error);
            }
            const data = await response.json();
            setPlaceholdersData(data.placeholders || {});
            setEmailObject(data.email_object);
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: false
            });
        }
    }, [codeLangId, triggerNotification, authFetch]);

    const fetchEmailPreview = useCallback(async () => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/email/simulation/${codeLangId}`); // Remplacement de fetch par authFetch
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || 'erreur lors de la simulation ');
            }
            setEmailContent(data.content);
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: false
            });
        }
    }, [codeLangId, triggerNotification, authFetch]);

    useEffect(() => {
        fetchData();
        fetchEmailPreview();
    }, [fetchData, fetchEmailPreview]);


    const handleEmailObjectChange = async (newEmailObject) => {
        setEmailObject(newEmailObject);
        try {
            const response = await authFetch(`${config.API_URL}/emailing/codelang/update-email-object/${codeLangId}`, { // Remplacement de fetch par authFetch
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email_object: newEmailObject })
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de la mise à jour');
            }
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: "Erreur lors de la mise à jour de l'objet de l'email: " + error.message,
                duration: 3000,
                persistent: false
            });
            console.error("Erreur lors de la mise à jour de l'objet de l'email:", error);
        }
    };

    const saveAllChanges = async () => {
        setIsLoading(true);
        try {
            await Promise.all(Object.entries(placeholdersData).map(async ([codeLangSingleKey, placeholders]) => {
                return Promise.all(placeholders.map(async (placeholder) => {
                    await authFetch(`${config.API_URL}/emailing/placeholder/update/${placeholder.uuid}`, {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ value: placeholder.value })
                    });
                }));
            }));

            await fetchEmailPreview();
        } catch (error) {
            console.error("Erreur lors de la mise à jour des placeholders:", error);
        } finally {
            setIsLoading(false);
        }
    };


    const handleContentChange = (codeLangSingleKey, updatedPlaceholders) => {
        setPlaceholdersData(prevState => ({
            ...prevState,
            [codeLangSingleKey]: updatedPlaceholders
        }));
    };

    return (
        <div>
            {isLoading && <Loader />}
            <Button onClick={saveAllChanges} className="button-fixed-bottom right">Sauvegarder les changements</Button>
            <Breadcrumb />
            <HelpPopUp instructions={helpInstructions} />

            <h1>Modifier le Code Lang</h1>

            <label htmlFor="campaignName"><strong>Objet de l'email</strong></label>:
            <TextInput
                id="emailObject"
                value={emailObject}
                onChange={(e) => handleEmailObjectChange(e.target.value)}
                placeholder="Objet de l'email"
            />

            {placeholdersData && Object.entries(placeholdersData).map(([codeLangSingleKey, placeholders]) => (
                <CodeLangSingleForm
                    key={codeLangSingleKey}
                    codeLangSingle={codeLangSingleKey}
                    placeholders={placeholders}
                    campaignId={campaignId}
                    onContentChange={handleContentChange}
                />
            ))}


            <EmailPreview content={emailContent} />
        </div>
    );
};

export default EditCodeLang;
